import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import useStyles from '../../../hooks/useStyles';
import { variableAction } from '../../../api/auth';
import amplitude from '../../../util/amplitude';

export default function Downloads({ licenseId }) {
  const classes = useStyles();

  const [fileName, setFileName] = useState('');
  const [releaseDate, setReleaseDate] = useState('');

  useEffect(() => {
    variableAction().then(({ value, updatedAt, createdAt }) => {
      if (value) {
        setFileName(value.slice(0, -4));
        setReleaseDate((updatedAt || createdAt).substring(0, 10));
      }
    });
  }, []);

  return (
    <>
      <CssBaseline />
      <br />
      <Typography variant="h5" gutterBottom>
        Downloads
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Software Version</TableCell>
              <TableCell>3ds Max Support</TableCell>
              <TableCell>Render Engine Support</TableCell>
              <TableCell>Release Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
    <TableBody>
                        <TableRow>
              <TableCell>
                <img
                  width="35"
                  height="35"
                  alt="Cityscape Pro Logo"
                  src="/resources/images/Cityscape_Logo_Large.png"
                />
              </TableCell>
              <TableCell>CityscapePro-2.1.0</TableCell>
              <TableCell>2017-2025</TableCell>
              <TableCell>Vray, Corona</TableCell>
              <TableCell>2024-07-25</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => amplitude.getInstance().logEvent('Download')}
                  href="https://simpolium.com/CityscapePro-2.1.0-manual.exe"
                >
                  Download
                </Button>
              </TableCell>
            </TableRow>
                        <TableRow>
              <TableCell>
                <img
                  width="35"
                  height="35"
                  alt="Cityscape Pro Logo"
                  src="/resources/images/Cityscape_Logo_Large.png"
                />
              </TableCell>
              <TableCell>CityscapePro-1.6.1</TableCell>
              <TableCell>2017-2024</TableCell>
              <TableCell>Vray, Corona</TableCell>
              <TableCell>2023-05-02</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => amplitude.getInstance().logEvent('Download')}
                  href="https://simpolium.com/CityscapePro-1.6.1.exe"
                >
                  Download
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <img
                  width="35"
                  height="35"
                  alt="Cityscape Pro Logo"
                  src="/resources/images/Cityscape_Logo_Large.png"
                />
              </TableCell>
              <TableCell>{fileName}</TableCell>
              <TableCell>2017-2023</TableCell>
              <TableCell>Vray, Corona</TableCell>
              <TableCell>{releaseDate}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => amplitude.getInstance().logEvent('Download')}
                  href={`https://accounts.simpolium.com/download/${licenseId.substr(0,8)}`}
                >
                  Download
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
